<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import * as Contracts from "@/services/contracts.js";
import moment from "moment";
import appConfig from "@/app.config";
import Table from "@/components/elements/Tables";
export default {
  page: {
    title: "All Contracts",
    meta: [{ name: "Contracts", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Table },
  created() {
    Contracts.GetAllContracts().then((data) => {
      this.tableData = data;
    });
  },

  data() {
    return {
      testData: [],
      tableData: [],
      title: this.$t("menuitems.contracts.text"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.contracts.text"),
        },
        {
          text: this.$t("all"),
          active: true,
        },
      ],

      filterOn: [],
      sortBy: "createdAt",

      fields: [
        {
          label: this.$t("client"),
          key: "clientName",
          sortable: false,
        },
        {
          label: this.$t("title"),
          key: "ContractTitle",
          sortable: false,
        },
        {
          label: this.$t("type"),
          key: `${
            this.$i18n.locale == "en" ? "contractType.en" : "contractType.ar"
          }`,
          sortable: false,
        },
        {
          label: this.$t("status"),
          key: "contractStatus",
          sortable: false,
          formatter: (value) => {
            return value === 0
              ? this.$t("not_reviewed")
              : value === 1
              ? this.$t("reviewed")
              : value === 2
              ? this.$t("paid")
              : this.$t("closed");
          },
          filterByFormatted: true,
        },
        {
          label: this.$t("date"),
          key: "createdAt",
          formatter: (value) => {
            return moment(value).format("YYYY-MM-DD HH:mm");
          },
          filterByFormatted: true,
          sortable: true,
        },
      ],
    };
  },
  methods: {
    rowClicked(item) {
      console.log(item);
      this.$router.push({ path: "/contract/" + item.ContractID });
    },
  },
  computed: {
    flattenedtabletData() {
      return this.tableData.map((contract) => {
        // Flatten the object structure and return a new object
        return {
          clientName: contract.clientName || "ــــــــ",
          clientId: contract.clientId,
          ContractID: contract.id,
          ContractTitle: contract.contractData.ContractTitle,
          contractType: contract.contractData.CotractType,
          contractStatus: contract.contractData.ContractStatus,
          createdAt: contract.createdAt,
          // Include other properties you want to flatten
        };
      });
    },
    // flattenedtabletData() {
    //   return this.tableData.map((contract) => {
    //     // Flatten the object structure and return a new object
    //     return {
    //       clientName: contract.clientName,
    //       ContractID: contract.id,
    //       ContractTitle: contract.contractData.ContractTitle,
    //       contractType: contract.contractData.CotractType,
    //       contractStatus: contract.contractData.ContractStatus,
    //       createdAt: contract.createdAt,
    //       // Include other properties you want to flatten
    //     };
    //   });
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Table
      :tableData="flattenedtabletData"
      :fields="fields"
      :sortBy="sortBy"
      :filterOn="filterOn"
      @rowClicked="rowClicked"
      :filter-keys="['clientName', 'contractStatus', 'contractType']"
    />
  </Layout>
</template>
